import {
	DiagramEngine,
	DefaultDiagramState,
	DiagramModel,
	PortModelAlignment,
	PointModel
} from '@projectstorm/react-diagrams'
import { MddNodeFactory, MddNodeModel } from './models/MddNodeModel'
import { NativeMap } from '@/utils/collections'
import {
	DiagramNode,
	DiagramLink,
	DiagramNodeType,
	DiagramNodeRelationship
} from '@/api/schemas/diagram'
import { MddLinkModel } from './models/link/MddLinkModel'
import { Point } from '@projectstorm/geometry'
import { TableDiagramDto } from '@/api/models'
import { MddLinkFactory } from './models/link/MddLinkFactory'
import {
	LinkLayerFactory,
	NodeLayerFactory
} from '@projectstorm/react-diagrams-core'
import {
	DefaultLabelFactory,
	DefaultLinkFactory,
	DefaultNodeFactory,
	DefaultPortFactory
} from '@projectstorm/react-diagrams-defaults'
import { SelectionBoxLayerFactory } from '@projectstorm/react-canvas-core'

export const MddCreateEngine = () => {
	const engine = new DiagramEngine()

	// register model factories
	engine.getLayerFactories().registerFactory(new NodeLayerFactory() as any)
	engine.getLayerFactories().registerFactory(new LinkLayerFactory() as any)
	engine.getLayerFactories().registerFactory(new SelectionBoxLayerFactory())

	engine.getLabelFactories().registerFactory(new DefaultLabelFactory())
	engine.getNodeFactories().registerFactory(new DefaultNodeFactory())
	engine.getNodeFactories().registerFactory(new MddNodeFactory())
	engine.getLinkFactories().registerFactory(new DefaultLinkFactory())
	engine.getLinkFactories().registerFactory(new MddLinkFactory())
	// crashes with pathfinding
	// engine.getLinkFactories().registerFactory(new PathFindingLinkFactory());
	engine.getPortFactories().registerFactory(new DefaultPortFactory())

	// register the default interaction behaviours
	engine.getStateMachine().pushState(new DefaultDiagramState())

	return engine
}

export const getEngine = () => {
	const engine = MddCreateEngine()
	const state = engine.getStateMachine().getCurrentState()

	if (state instanceof DefaultDiagramState) {
		state.dragNewLink.config.allowLooseLinks = false
	}

	return engine
}

export const engineFitNodes = (engine: DiagramEngine) => {
	const canvas = engine.getCanvas()
	const model = engine.getModel()

	const minX = Math.min(
		...Object.values(model.getNodes()).map(node => node.getX())
	)

	const minY = Math.min(
		...Object.values(model.getNodes()).map(node => node.getY())
	)

	const maxX = Math.max(
		...Object.values(model.getNodes()).map(
			node => node.getBoundingBox().getBottomRight().x
		)
	)

	const maxY = Math.max(
		...Object.values(model.getNodes()).map(
			node => node.getBoundingBox().getBottomRight().y
		)
	)

	const width = maxX - minX
	const height = maxY - minY

	const centerX = minX + width * 0.5
	const centerY = minY + height * 0.5

	const actualWidth = Math.max(
		width + canvas.clientWidth * 0.2,
		canvas.clientWidth * 0.75
	)

	const actualHeight = Math.max(
		height + canvas.clientHeight * 0.2,
		canvas.clientHeight * 0.75
	)

	const xFactor = canvas.clientWidth / actualWidth
	const yFactor = canvas.clientHeight / actualHeight
	const zoomFactor = xFactor < yFactor ? xFactor : yFactor

	model.setZoomLevel(zoomFactor * 100)

	model.setOffset(
		-(centerX * zoomFactor - actualWidth * 0.5 * xFactor),
		-(centerY * zoomFactor - actualHeight * 0.5 * yFactor)
	)

	engine.repaintCanvas()
}

export const createNode = (
	model: DiagramModel,
	diagramNode: DiagramNode,
	createdNodes: NativeMap<MddNodeModel>,
	positionChanged: () => void,
	editMode?: boolean
) => {
	const node = new MddNodeModel(
		diagramNode.nodeId,
		diagramNode.type,
		diagramNode.relationship,
		diagramNode.code,
		positionChanged
	)

	node.nodeId = diagramNode.nodeId
	node.setPosition(diagramNode.x, diagramNode.y)
	node.setLocked(!editMode)

	node.registerListener({
		positionChanged
	})

	model.addNode(node)
	createdNodes[diagramNode.id] = node
}

export const createLink = (
	model: DiagramModel,
	diagramLink: DiagramLink,
	createdNodes: NativeMap<MddNodeModel>,
	positionChanged: () => void,
	editMode?: boolean
) => {
	const sourceNode = createdNodes[diagramLink.from]
	const targetNode = createdNodes[diagramLink.to]

	if (!sourceNode || !targetNode) {
		console.error(
			'Unable to find target/source for',
			diagramLink,
			'src',
			sourceNode,
			'target',
			targetNode
		)

		return
	}

	const sourcePort = sourceNode.getPort(PortModelAlignment.BOTTOM)
	const targetPort = targetNode.getPort(PortModelAlignment.TOP)

	if (!sourcePort || !targetPort) {
		console.error(
			'Unable to find target/source port',
			diagramLink,
			'src',
			sourcePort,
			'target',
			targetPort
		)

		return
	}

	const link = new MddLinkModel({ onChange: positionChanged })
	link.setSourcePort(sourcePort)
	link.setTargetPort(targetPort)
	link.addLabel(diagramLink.label ?? '')

	diagramLink.points?.forEach((p, i, arr) => {
		if (i === 0 || i === arr.length - 1) {
			return
		}

		const point = new PointModel({
			link: link,
			position: new Point(p.x, p.y)
		})

		link.addPoint(point)
	})

	link.setLocked(!editMode)

	model.addLink(link)
}

/**
 * Add / remove / update any nodes & links from api to form (tables created / removed / changed after diagram creation)
 * @param nodes form
 * @param links form
 * @param dataApi api data
 */
export const getAllNodesAndLinks = (
	nodes?: DiagramNode[],
	links?: DiagramLink[],
	dataApi?: TableDiagramDto
): {
	nodesAll: DiagramNode[]
	linksAll: DiagramLink[]
} => {
	if (!dataApi) {
		return {
			nodesAll: nodes ?? [],
			linksAll: links ?? []
		}
	}

	// set new tables above tables in diagram in a row
	const min = nodes?.reduce(
		(acc, cur) => {
			if (acc.y > cur.y) {
				return {
					x: cur.x + 185,
					y: cur.y - 125
				}
			}

			return acc
		},
		{
			x: 99999,
			y: 99999
		}
	) ?? { x: 0, y: 0 }

	const nodesAll = dataApi.nodes?.map(n => {
		const nodeForm = nodes?.find(node => node.nodeId === n.id?.toString())

		if (!nodeForm) {
			min.x += 185
		}

		return {
			id: n.id ?? '-1',
			nodeId: n.id?.toString() ?? '-1',
			code: n.code,
			x: nodeForm ? nodeForm.x : min.x,
			y: nodeForm ? nodeForm.y : min.y,
			type: DiagramNodeType.TABLE,
			relationship: n.relationship ?? DiagramNodeRelationship.DIRECT
		} as DiagramNode
	})

	const linksAll = dataApi.links?.map(l => {
		const linkForm = links?.find(
			link =>
				link.from === l.foreignKeyTableId?.toString() &&
				link.to === l.primaryKeyTableId?.toString()
		)

		return {
			from: l.foreignKeyTableId?.toString() ?? '-1',
			to: l.primaryKeyTableId?.toString() ?? '-1',
			label: l.constraintCode,
			points: linkForm ? linkForm.points : []
		} as DiagramLink
	})

	return {
		nodesAll: nodesAll ?? [],
		linksAll: linksAll ?? []
	}
}

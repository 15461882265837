export interface DiagramData {
	nodes: DiagramNode[]
	links: DiagramLink[]
	zoom: number
	offset: {
		x?: number
		y?: number
	}
	autoLayoutFlag: boolean
}

export enum DiagramNodeRelationship {
	DIRECT = 'DIRECT',
	REFERENCED = 'REFERENCED'
}

export interface DiagramNode {
	id: string
	nodeId: string
	code: string
	type: DiagramNodeType
	relationship: DiagramNodeRelationship
	x: number
	y: number
}

export interface DiagramPoint {
	x: number
	y: number
}

export interface DiagramLink {
	from: string
	to: string
	label?: string
	points?: DiagramPoint[]
}

export enum DiagramNodeType {
	TABLE = 'table'
}

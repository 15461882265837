import {
	DefaultLinkModel,
	PointModel,
	DefaultLinkModelOptions
} from '@projectstorm/react-diagrams'

interface MddLinkModelOptions extends DefaultLinkModelOptions {
	onChange: () => void
}

export class MddLinkModel extends DefaultLinkModel {
	onChange: () => void

	constructor(options: MddLinkModelOptions) {
		super({ ...options, curvyness: 0, type: 'Mdd' })
		this.onChange = options.onChange
	}
	remove() {
		return false
	}
	setPoints(points: PointModel[]) {
		points.forEach(point => {
			point.setParent(this)

			point.registerListener({
				entityRemoved: this.onChange,
				positionChanged: this.onChange
			})
		})

		this.points = points
	}
	addPoint<P extends PointModel>(pointModel: P, index = 1): P {
		pointModel.setParent(this)
		this.points.splice(index, 0, pointModel)

		pointModel.registerListener({
			entityRemoved: this.onChange,
			positionChanged: this.onChange
		})

		return pointModel
	}
}

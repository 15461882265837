import React from 'react'
import { PointModel } from '@projectstorm/react-diagrams'

export const MddLinkArrow = ({
	point,
	previousPoint,
	color,
	setSelected,
	colorSelected,
	selected
}: {
	point: PointModel
	previousPoint: PointModel
	color: string
	colorSelected: string
	setSelected: (selected: boolean) => void
	selected: boolean
}) => {
	// const angle =
	// 	90 +
	// 	(Math.atan2(
	// 		point.getPosition().y - previousPoint.getPosition().y,
	// 		point.getPosition().x - previousPoint.getPosition().x
	// 	) *
	// 		180) /
	// 		Math.PI

	const propsCommon = {
		stroke: selected ? colorSelected : color,
		strokeWidth: 2
	}

	return (
		<g
			onMouseEnter={() => setSelected(true)}
			onMouseLeave={() => setSelected(false)}
			style={selected ? { zIndex: 999 } : {}}
			transform={
				'translate(' +
				point.getPosition().x +
				', ' +
				point.getPosition().y +
				')'
			}
		>
			{/* <g style={{ transform: 'rotate(' + angle + 'deg)' }}> */}
			<g style={{ transform: 'rotate(' + 0 + 'deg)' }}>
				<g transform={'translate(0, -10)'}>
					<line x1="0" y1="10" x2="10" y2="0" {...propsCommon} />
					<line x1="0" y1="10" x2="-10" y2="0" {...propsCommon} />
					<line x1="0" y1="10" x2="0" y2="0" {...propsCommon} />
				</g>
			</g>
		</g>
	)
}

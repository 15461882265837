import React, { useEffect, useMemo } from 'react'
import {
	DiagramEngine,
	PortWidget,
	PortModelAlignment
} from '@projectstorm/react-diagrams'
import styled, { css } from 'styled-components'
import { TableWidget } from './TableWidget'
import { DiagramNodeType } from '@/api/schemas/diagram'
import { mainColors } from '@/styles'
import { MddNodeModel } from '../models/MddNodeModel'

type Props = {
	node: MddNodeModel
	engine: DiagramEngine
}

export const MddWidget = ({ engine, node }: Props) => {
	const portTop = useMemo(() => node.getPort(PortModelAlignment.TOP), [node])

	const portBottom = useMemo(() => node.getPort(PortModelAlignment.BOTTOM), [
		node
	])

	useEffect(() => {
		portTop?.setLocked(true)
		portBottom?.setLocked(true)
	}, [])

	const ports = (
		<>
			{portTop && (
				<PortWidget
					style={{
						left: '50%',
						top: '0',
						marginTop: -2,
						position: 'absolute',
						transform: 'translate(-50%, 0)'
					}}
					port={portTop}
					engine={engine}
				>
					<Port isSelected={node.isSelected()} isLocked={node.isLocked()} top />
				</PortWidget>
			)}
			{portBottom && (
				<PortWidget
					style={{
						left: '50%',
						top: '100%',
						marginTop: 8,
						position: 'absolute',
						transform: 'translate(-50%, 0)'
					}}
					port={portBottom}
					engine={engine}
				>
					<Port isSelected={node.isSelected()} isLocked={node.isLocked()} />
				</PortWidget>
			)}
		</>
	)

	switch (node.nodeType) {
		case DiagramNodeType.TABLE: {
			return <TableWidget node={node} ports={ports} />
		}
	}

	return (
		<div>
			<span>{node.nodeType}</span>
			{ports}
		</div>
	)
}

const Port = styled.div<{
	isSelected: boolean
	isLocked: boolean
	top?: boolean
}>`
	width: 0;
	height: 0;
	z-index: 10;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	cursor: pointer;

	${props =>
		props.top &&
		css`
			background: ${mainColors.primary};
		`}

	${props =>
		props.isSelected &&
		css`
			background: rgba(0, 192, 255, 0.5);

			&:hover {
				background: rgba(0, 192, 255, 1);
			}
		`}

	${props =>
		props.isLocked &&
		css`
			width: 0;
			height: 0;
			border-radius: 4px;
		`}
`

import * as React from 'react'
import { MddLinkModel } from './MddLinkModel'
import { MddLinkWidget } from './MddLinkWidget'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'
import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export const Keyframes = keyframes`
		from {
			stroke-dashoffset: 30;
		}
		to {
			stroke-dashoffset: 0;
		}
	`

const selected = css`
	stroke-dasharray: 4, 6;
	animation: ${Keyframes} 1s linear infinite;
`

export const Path = styled.path<{ selected: boolean }>`
	${p => p.selected && selected};
	fill: none;
	pointer-events: all;
`

export class MddLinkFactory<
	Link extends MddLinkModel = MddLinkModel
> extends AbstractReactFactory<Link, DiagramEngine> {
	constructor(type = 'Mdd') {
		super(type)
	}

	generateReactWidget(event: any): JSX.Element {
		return <MddLinkWidget link={event.model} diagramEngine={this.engine} />
	}

	generateModel(event: any): Link {
		return new MddLinkModel({ onChange: () => undefined }) as Link
	}

	generateLinkSegment(model: Link, selected: boolean, path: string) {
		return (
			<Path
				selected={selected}
				stroke={
					selected ? model.getOptions().selectedColor : model.getOptions().color
				}
				strokeWidth={model.getOptions().width}
				strokeLinecap="round"
				strokeLinejoin="round"
				d={path}
			/>
		)
	}
}

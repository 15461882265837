import * as React from 'react'
import {
	DiagramEngine,
	LinkWidget,
	PointModel
} from '@projectstorm/react-diagrams-core'
import { MddLinkModel } from './MddLinkModel'
import { MddLinkPointWidget } from './MddLinkPointWidget'
import { MddLinkSegmentWidget } from './MddLinkSegmentWidget'
import { MouseEvent } from 'react'
import { MddLinkArrow } from './MddLinkArrow'

export interface MddLinkProps {
	link: MddLinkModel
	diagramEngine: DiagramEngine
	pointAdded?: (point: PointModel, event: MouseEvent) => any
}

export interface MddLinkState {
	selected: boolean
}

export class MddLinkWidget extends React.Component<MddLinkProps, MddLinkState> {
	refPaths: React.RefObject<SVGPathElement>[]

	constructor(props: MddLinkProps) {
		super(props)
		this.refPaths = []

		this.state = {
			selected: false
		}
	}

	componentDidUpdate(): void {
		const paths = this.refPaths.map(ref => {
			return ref.current
		})

		this.props.link.setRenderedPaths(paths as SVGPathElement[])
	}

	componentDidMount(): void {
		const paths = this.refPaths.map(ref => {
			return ref.current
		})

		this.props.link.setRenderedPaths(paths as SVGPathElement[])
	}

	componentWillUnmount(): void {
		this.props.link.setRenderedPaths([])
	}

	setSelected = (selected: boolean) => {
		this.setState({ selected })
	}

	addPointToLink(event: MouseEvent, index: number) {
		if (
			!event.shiftKey &&
			!this.props.link.isLocked() &&
			this.props.link.getPoints().length - 1 <=
				this.props.diagramEngine.getMaxNumberPointsPerLink()
		) {
			const point = new PointModel({
				link: this.props.link,
				position: this.props.diagramEngine.getRelativeMousePoint(event)
			})

			this.props.link.addPoint(point, index)
			event.persist()
			event.stopPropagation()

			this.forceUpdate(() => {
				this.props.diagramEngine.getActionEventBus().fireAction({
					event,
					model: point
				})
			})
		}
	}

	generatePoint(point: PointModel): JSX.Element {
		return (
			<MddLinkPointWidget
				key={point.getID()}
				point={point as any}
				colorSelected={this.props.link.getOptions().selectedColor as string}
				color={this.props.link.getOptions().color}
			/>
		)
	}

	generateLink(
		path: string,
		extraProps: any,
		id: string | number
	): JSX.Element {
		const ref = React.createRef<SVGPathElement>()
		this.refPaths.push(ref)

		return (
			<MddLinkSegmentWidget
				key={`link-${id}`}
				path={path}
				selected={this.state.selected}
				diagramEngine={this.props.diagramEngine}
				factory={this.props.diagramEngine.getFactoryForLink(this.props.link)}
				link={this.props.link}
				forwardRef={ref}
				onSelection={selected => {
					this.setState({ selected: selected })
				}}
				extras={extraProps}
			/>
		)
	}

	generateArrow(point: PointModel, previousPoint: PointModel): JSX.Element {
		return (
			<MddLinkArrow
				key={point.getID()}
				point={point}
				previousPoint={previousPoint}
				colorSelected={this.props.link.getOptions().selectedColor as string}
				color={this.props.link.getOptions().color as string}
				setSelected={this.setSelected}
				selected={this.state.selected}
			/>
		)
	}

	render() {
		//ensure id is present for all points on the path
		const points = this.props.link.getPoints()
		const paths = []
		this.refPaths = []

		// draw tripod
		if (this.props.link.getSourcePort() !== null) {
			paths.push(this.generateArrow(points[0], points[1] ?? points[0]))
		}

		//draw the multiple anchors and complex line instead
		for (let j = 0; j < points.length - 1; j++) {
			paths.push(
				this.generateLink(
					LinkWidget.generateLinePath(points[j], points[j + 1]),
					{
						'data-linkid': this.props.link.getID(),
						'data-point': j,
						onMouseDown: (event: MouseEvent) => {
							this.addPointToLink(event, j + 1)
						}
					},
					j
				)
			)
		}

		//render the circles
		for (let i = 1; i < points.length - 1; i++) {
			paths.push(this.generatePoint(points[i]))
		}

		return <g>{paths}</g>
	}
}

import React from 'react'
import styled from 'styled-components'
import {
	DiagramEngine,
	DiagramModel,
	DiagramModelGenerics
} from '@projectstorm/react-diagrams'
import { Button } from '@/components'
import {
	faExpandArrowsAlt,
	faProjectDiagram,
	faPlus,
	faMinus
} from '@fortawesome/free-solid-svg-icons'
import { engineFitNodes } from '../utils'
import { useAppContext } from '@/utils/hooks'
import { OpenedFolderData } from '@/store/modules/folder/types'

type Props = {
	engine: DiagramEngine
	handleLayout: (model: DiagramModel<DiagramModelGenerics>) => void
	model: DiagramModel<DiagramModelGenerics>
	editMode: boolean
	data: OpenedFolderData
}

export const Controls = ({
	engine,
	editMode,
	handleLayout,
	model,
	data
}: Props) => {
	const { t } = useAppContext()

	const handleFitClick = () => {
		engineFitNodes(engine)
	}

	const autoLayoutFlag = data.form.diagram?.autoLayoutFlag

	const changeZoom = (change: number) => {
		model.setZoomLevel(model.getZoomLevel() + change)
		engine.repaintCanvas()
	}

	return (
		<Container>
			<ControlButton
				size="sm"
				icon={faExpandArrowsAlt}
				onClick={handleFitClick}
				title={t('ZOOM_TO_FIT')}
			/>
			<ControlButton
				size="sm"
				icon={faPlus}
				onClick={() => {
					changeZoom(25)
				}}
				title={t('ZOOM_IN')}
			/>
			<ControlButton
				size="sm"
				icon={faMinus}
				onClick={() => {
					changeZoom(-25)
				}}
				title={t('ZOOM_OUT')}
			/>
			<ControlButton
				size="sm"
				icon={faProjectDiagram}
				onClick={() => handleLayout(model)}
				schema={autoLayoutFlag ? 'primary' : 'warn'}
				disabled={!editMode}
			>
				{t(autoLayoutFlag ? 'DIAGRAM_AUTO_LAYOUT' : 'DIAGRAM_MANUAL_LAYOUT')}
			</ControlButton>
		</Container>
	)
}

const ControlButton = styled(Button)`
	margin: 5px;
`

const Container = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid #eee;
	flex-grow: 0;
`

import React from 'react'
import styled, { css } from 'styled-components'
import { MddNodeModel } from '../models/MddNodeModel'
import { DiagramNodeRelationship } from '@/api/schemas/diagram'

type Props = {
	node: MddNodeModel
	ports: React.ReactNode
}

export const TableWidget = ({ node, ports }: Props) => {
	return (
		<Table selected={node.isSelected()} relationship={node.relationship}>
			<Title title={node.code} relationship={node.relationship}>
				{node.code?.toLowerCase()}
			</Title>
			<Content>{ports}</Content>
		</Table>
	)
}

const Title = styled.div<{ relationship: DiagramNodeRelationship }>`
	height: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;
	overflow: hidden;
	padding: 10px 10px;
	border-bottom: 1px solid #999;
	background: #ddd;
	margin-bottom: 25px;
	text-transform: capitalize;
	${props =>
		props.relationship === DiagramNodeRelationship.REFERENCED &&
		css`
			background: #fff;
		`}
`

const Content = styled.div``

const Table = styled.div<{
	selected: boolean
	relationship: DiagramNodeRelationship
}>`
	border: 1px solid #999;
	box-sizing: border-box;
	width: 160px;
	background: #fff;
	overflow: hidden;

	${props => css`
		&:hover {
			border-color: transparent;
			box-shadow: 0 0 0 2px ${props.theme.colors.graph.selectedBorder};
		}
	`}

	${props =>
		props.selected &&
		css`
			border-color: transparent;
			box-shadow: 0 0 0 2px ${props.theme.colors.graph.selectedBorder};
		`}
`

import React, { useMemo, useCallback, useState, useEffect } from 'react'
import {
	DiagramModel,
	DiagramModelGenerics
} from '@projectstorm/react-diagrams'
import styled from 'styled-components'
import { Layer } from './components/Layer'
import { Controls } from './components/Controls'
import { getEngine, createNode, createLink, getAllNodesAndLinks } from './utils'
import { MddNodeModel } from './models/MddNodeModel'
import { NativeMap } from '@/utils/collections'
import { debounce } from 'debounce'
import { DiagramNodeType } from '@/api/schemas/diagram'
import { FolderData, OpenedFolderData } from '@/store/modules/folder/types'
import { DagreEngine } from './components/DagreEngine'
import { useApi } from '@/api/hooks'
import { getDeepDiagram } from '@/api'
import { Loader } from '@/components'

const Diagram = ({
	data,
	nodeId,
	onChange
}: {
	data: OpenedFolderData
	nodeId: number
	onChange: (update: Partial<FolderData>) => void
}) => {
	const [dataApi, isLoading, isReloading] = useApi(getDeepDiagram(nodeId))

	const [model, setModel] = useState<DiagramModel<DiagramModelGenerics> | null>(
		null
	)

	const engine = useMemo(() => getEngine(), [])

	const engineLayout = useMemo(
		() =>
			new DagreEngine(
				{
					graph: {
						rankdir: 'TB',
						ranker: 'longest-path',
						marginx: 25,
						marginy: 25,
						nodesep: 125,
						ranksep: 125
					},
					includeLinks: true
				},
				engine
			),
		[engine]
	)

	const editMode = data.parsedEditMode

	const handleChange = useCallback(
		(e?: any, autoLayoutFlag?: boolean) => {
			const model = engine.getModel()

			if (!model || !editMode) {
				return
			}

			const nodes = model.getNodes()
			const links = model.getLinks()

			onChange({
				diagram: {
					...(autoLayoutFlag !== undefined && { autoLayoutFlag }),
					nodes: nodes.map(node => ({
						id: node instanceof MddNodeModel ? node.nodeId : '-1',
						code: node instanceof MddNodeModel ? node.code : '-1',
						nodeId: node instanceof MddNodeModel ? node.nodeId : '-1',
						type:
							node instanceof MddNodeModel
								? (node.nodeType as DiagramNodeType)
								: DiagramNodeType.TABLE,
						x: node.getX(),
						y: node.getY()
					})),
					links: links
						.filter(link => link.getSourcePort() && link.getTargetPort())
						.map(link => {
							const labels = link
								.getLabels()
								.map(l => (l.serialize() as any).label)

							const points = link
								.getPoints()
								.map(p => {
									const { x, y } = p.getPosition()

									return {
										x,
										y
									}
								})
								.reverse()

							return {
								from: (link.getSourcePort().getNode() as MddNodeModel).nodeId,
								to: (link.getTargetPort().getNode() as MddNodeModel).nodeId,
								points,
								label: labels.length > 0 ? labels[0] : ''
							}
						})
				}
			} as Partial<FolderData>)
		},
		[editMode, onChange]
	)

	const positionChanged = useCallback(
		(event?: any) => {
			if (engineLayout.inProgress > 0) {
				return
			}

			const positionDebounced = debounce(() => handleChange(undefined, false))
			positionDebounced()
		},
		[handleChange, engineLayout.inProgress]
	)

	const handleLayout = useCallback(
		debounce((model: DiagramModel<DiagramModelGenerics>) => {
			engineLayout.redistribute(model as any)
			handleChange(undefined, true)
		}),
		[handleChange, engineLayout.redistribute]
	)

	useEffect(() => {
		if (isReloading || !dataApi) {
			return
		}

		const model = new DiagramModel()
		const createdNodes = {} as NativeMap<MddNodeModel>

		const { linksAll, nodesAll } = getAllNodesAndLinks(
			data.form.diagram?.nodes,
			data.form.diagram?.links,
			dataApi
		)

		nodesAll.forEach(node =>
			createNode(model, node, createdNodes, positionChanged, editMode)
		)

		linksAll.forEach(link =>
			createLink(model, link, createdNodes, positionChanged, editMode)
		)

		if (data.form.diagram && !data.form.diagram?.autoLayoutFlag) {
			if (data.form.diagram.zoom) {
				model.setZoomLevel(data.form.diagram.zoom * 100)
			}

			if (data.form.diagram.offset.x && data.form.diagram.offset.y) {
				model.setOffset(data.form.diagram.offset.x, data.form.diagram.offset.y)
			}
		}

		engine.setModel(model)

		if (data.form.diagram?.autoLayoutFlag) {
			handleLayout(model)
		}

		setModel(model)
	}, [engine, dataApi, editMode, isReloading, positionChanged, handleLayout])

	return (
		<>
			{model && !isReloading ? (
				<GraphContainer>
					<Controls
						engine={engine}
						handleLayout={handleLayout}
						model={model}
						editMode={editMode}
						data={data}
					/>
					<Layer
						model={model}
						engine={engine}
						onChange={onChange}
						data={data}
					/>
				</GraphContainer>
			) : (
				<Loader loaded={!isReloading} />
			)}
		</>
	)
}

const GraphContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`

export default Diagram

import {
	DiagramModel,
	PointModel,
	DiagramEngine
} from '@projectstorm/react-diagrams-core'
import * as dagre from 'dagre'
import { GraphLabel } from 'dagre'
import { Point } from '@projectstorm/geometry'
import { engineFitNodes } from '../utils'

export interface DagreEngineOptions {
	graph?: GraphLabel
	includeLinks?: boolean
}

export class DagreEngine {
	options: DagreEngineOptions
	inProgress: number
	engine: DiagramEngine

	constructor(options: DagreEngineOptions = {}, engine: DiagramEngine) {
		this.options = options
		this.inProgress = 0
		this.engine = engine
	}

	progressAdd() {
		this.inProgress += 1
	}
	progressRemove() {
		this.inProgress -= 1
	}

	redistribute(model?: DiagramModel) {
		if (!model || this.inProgress > 0) {
			return
		}

		this.progressAdd()

		const g = new dagre.graphlib.Graph({
			multigraph: true
		})

		g.setGraph(this.options.graph || {})

		g.setDefaultEdgeLabel(function() {
			return {}
		})

		const processedlinks: { [id: string]: boolean } = {}

		model.getNodes().forEach(node => {
			g.setNode(node.getID(), { width: node.width, height: node.height })
		})

		model.getLinks().forEach(link => {
			if (link.getSourcePort() && link.getTargetPort()) {
				processedlinks[link.getID()] = true

				g.setEdge({
					v: link
						.getSourcePort()
						.getNode()
						.getID(),
					w: link
						.getTargetPort()
						.getNode()
						.getID(),
					name: link.getID()
				})
			}
		})

		dagre.layout(g)

		g.nodes().forEach(v => {
			const node = g.node(v)

			model
				.getNode(v)
				.setPosition(node.x - node.width / 2, node.y - node.height / 2)
		})

		if (this.options.includeLinks) {
			g.edges().forEach(e => {
				const edge = g.edge(e)
				const link = model.getLink(e.name as string)

				const points = [link.getFirstPoint()]

				for (let i = 1; i < edge.points.length - 2; i++) {
					points.push(
						new PointModel({
							link: link,
							position: new Point(edge.points[i].x, edge.points[i].y)
						})
					)
				}

				link.setPoints(points.concat(link.getLastPoint()))
			})
		}

		if (this.engine.getCanvas() !== undefined) {
			engineFitNodes(this.engine)
		}

		this.progressRemove()
	}
}

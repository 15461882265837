import React from 'react'
import { DiagramNodeType, DiagramNodeRelationship } from '@/api/schemas/diagram'
import {
	DefaultNodeModel,
	PortModelAlignment,
	DiagramEngine
} from '@projectstorm/react-diagrams'
import { MddPortModel } from './MddPortModel'
import { MddWidget } from '../components/MddWidget'

import {
	AbstractReactFactory,
	GenerateWidgetEvent
} from '@projectstorm/react-canvas-core'

export class MddNodeModel extends DefaultNodeModel {
	nodeType: DiagramNodeType
	relationship: DiagramNodeRelationship
	onChange: () => void
	nodeId?: string
	code?: string

	constructor(
		nodeId: string,
		nodeType: DiagramNodeType,
		relationship: DiagramNodeRelationship,
		code: string,
		onChange: () => void
	) {
		super({
			type: 'mdd-widget'
		})

		this.nodeId = nodeId
		this.onChange = onChange
		this.nodeType = nodeType
		this.code = code
		this.relationship = relationship

		this.addPort(new MddPortModel(PortModelAlignment.TOP, 'in', onChange))
		this.addPort(new MddPortModel(PortModelAlignment.BOTTOM, 'out', onChange))
	}

	remove() {
		return false
	}
}

export class MddNodeFactory extends AbstractReactFactory<
	MddNodeModel,
	DiagramEngine
> {
	constructor() {
		super('mdd-widget')
	}

	generateReactWidget(event: GenerateWidgetEvent<MddNodeModel>) {
		return <MddWidget engine={this.engine} node={event.model} />
	}

	generateModel() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return null as any
	}
}

import {
	DefaultPortModel,
	PortModelAlignment
} from '@projectstorm/react-diagrams'
import { MddLinkModel } from './link/MddLinkModel'

export class MddPortModel extends DefaultPortModel {
	onChange: () => void

	constructor(
		alignment: PortModelAlignment,
		type: 'in' | 'out',
		onChange: () => void
	) {
		super({
			name: alignment,
			alignment,
			type
		})

		this.onChange = onChange
	}

	remove() {
		return false
	}

	createLinkModel(): MddLinkModel {
		return new MddLinkModel({ onChange: this.onChange })
	}
}

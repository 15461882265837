import * as React from 'react'
import { MddLinkFactory } from './MddLinkFactory'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'
import { MddLinkModel } from './MddLinkModel'

export interface MddLinkSegmentWidgetProps {
	path: string
	link: MddLinkModel
	selected: boolean
	forwardRef: React.RefObject<SVGPathElement>
	factory: MddLinkFactory
	diagramEngine: DiagramEngine
	onSelection: (selected: boolean) => any
	extras: object
}

export class MddLinkSegmentWidget extends React.Component<
	MddLinkSegmentWidgetProps
> {
	render() {
		const Bottom = React.cloneElement(
			this.props.factory.generateLinkSegment(
				this.props.link,
				this.props.selected || this.props.link.isSelected(),
				this.props.path
			),
			{
				ref: this.props.forwardRef
			}
		)

		const Top = React.cloneElement(Bottom, {
			strokeLinecap: 'round',
			onMouseLeave: () => {
				this.props.onSelection(false)
			},
			onMouseEnter: () => {
				this.props.onSelection(true)
			},
			...this.props.extras,
			ref: null,
			'data-linkid': this.props.link.getID(),
			strokeOpacity: this.props.selected ? 0.1 : 0,
			strokeWidth: 20,
			fill: 'none',
			onContextMenu: () => {
				if (!this.props.link.isLocked()) {
					;(event as any)?.preventDefault()
					this.props.link.remove()
				}
			}
		})

		return (
			<g>
				{Bottom}
				{Top}
			</g>
		)
	}
}
